import React, { useState, useEffect } from 'react';
import MultiSelect from "../../components/Forms/Select/MultiSelect";
import axios from 'axios';
import Switcher from "../../components/Switchers/Switcher";
import { Toaster, toast } from 'sonner';
import { BsRobot, BsTrash, BsPencil } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import SettingBot from './SettingBot/SettingBot';
import InputField from '../../components/Forms/Inputs/InputField';
import MultipleChoose from '../../components/Forms/Select/MultipleChoose';
import { BenchmarkPeriod, DownloadItem } from '../../types/period';
import { TASK, Bot } from '../../types/task'; 
import DeleteBot from './SettingBot/DeleteBot';

interface EditTaskProps {
  toggleModal: () => void;
  task: TASK; 
  updateTasks: () => void;
  onSave?: (result: boolean) => void; 
}

const EditTask: React.FC<EditTaskProps> = ({ 
  toggleModal, 
  task, 
  updateTasks,
  onSave,
}) => {
  const [newName, setNewName] = useState(task.name);
  const [newSaveOrders, setNewSaveOrders] = useState(task.saveOrders);
  const [newStatus, setNewStatus] = useState(task.status);
  const [newComment, setNewComment] = useState(task.comment);
  const [newBotBalance, setNewBotBalance] = useState<number | ''>('');
  const [newSymbol, setNewSymbol] = useState<string[]>(task.symbol.map(s => s.name));
  const [newLeverage, setNewLeverage] = useState<number | ''>('');
  const [newMaxMargin, setNewMaxMargin] = useState<number | ''>('');
  const [newLiquidationRate, setNewLiquidationRate] = useState<number | ''>('');
  const [newMaintenance, setNewMaintenance] = useState<number | ''>('');
  const [optionsSymbol, setOptionsSymbol] = useState<{ value: string; label: string }[]>([]);
  const [newBots, setNewBots] = useState<Bot[]>(task.bots); 
  const [isModalBot, setIsModalBot] = useState(false);
  const [periods, setPeriods] = useState<DownloadItem[]>([]);
  const [chooseSymbolPeriods, setChooseSymbolPeriods] = useState<DownloadItem[]>(task.chooseSymbolPeriods || []); 
  const [editingBot, setEditingBot] = useState<Bot | undefined>(undefined); 
  const [isModalDeleteBot, setIsModalDeleteBot] = useState(false);
  const [botToDeleteIndex, setBotToDeleteIndex] = useState<number | null>(null);

  const toggleModalDeleteBot = () => {
    setIsModalDeleteBot(!isModalDeleteBot);
  };

  useEffect(() => {
    setNewName(task.name);
    setNewSaveOrders(task.saveOrders);
    setNewStatus(task.status);
    setNewComment(task.comment);
    setNewBotBalance(task.botBalance);
    setNewSymbol(task.symbol.map(s => s.name));
    setNewLeverage(task.leverage);
    setNewMaxMargin(task.maxMargin);
    setNewLiquidationRate(task.liquidationRate);
    setNewMaintenance(task.maintenance);
    setNewBots(task.bots); 
    setChooseSymbolPeriods(task.chooseSymbolPeriods || []); 

    const fetchSymbols = async () => {
      try {
        const response = await axios.get('/get-benchmark-symbols');
        const symbols = response.data.map((symbol: { name: string }) => ({
          value: symbol.name,
          label: symbol.name,
        }));
        setOptionsSymbol(symbols);
      } catch (error) {
        console.error('Ошибка при получении символов:', error);
      }
    };

    fetchSymbols();

    if (task.symbol.length > 0) {
      fetchPeriods(task.symbol[0].name);
    }
  }, [task]);

  const handleSelectChange = (values: string[]) => {
    setNewSymbol(values);
    if (values.length > 0) {
      fetchPeriods(values[0]);
    } else {
      setPeriods([]);
    }
  };

  const fetchPeriods = async (symbol: string) => {
    try {
      const response = await axios.get<BenchmarkPeriod>(`/get-symbol-periods/${symbol}`);
      setPeriods(response.data.symbolPeriods);
    } catch (error) {
      console.error('Ошибка при получении периодов:', error);
      toast.error('Ошибка при получении периодов');
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleSaveOrdersChange = () => {
    setNewSaveOrders(!newSaveOrders);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewStatus(e.target.value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value);
  };

  const handleBotBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewBotBalance(value === '' ? '' : parseInt(value, 10));
  };

  const handleLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewLeverage(value === '' ? '' : parseInt(value, 10));
  };

  const handleMaxMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewMaxMargin(value === '' ? '' : parseInt(value, 10));
  };

  const handleLiquidationRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewLiquidationRate(value === '' ? '' : parseFloat(value));
  };

  const handleMaintenanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewMaintenance(value === '' ? '' : parseInt(value, 10));
  };

  const handleSelectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods([...chooseSymbolPeriods, period]);
  };

  const handleDeselectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods(chooseSymbolPeriods.filter(p => p.periodId !== period.periodId));
  };

  const handleUpdateTask = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newName === '' || newBotBalance === '' || newLeverage === '' || newSymbol.length === 0 || newMaxMargin === '' || newLiquidationRate === '' || newMaintenance === '') {
      toast.error('Пожалуйста, заполните все поля');
      return;
    }

    const formattedSymbol = newSymbol.map(value => ({ name: value }));

    const formattedBots = newBots.map(bot => ({
      ...bot,
      initUsdt: Number(bot.initUsdt),
      openProcentMax: bot.openProcentMax !== undefined ? Number(bot.openProcentMax) : 0,
      stopProcent: bot.stopProcent !== undefined ? Number(bot.stopProcent) : 0,
      takeProfit: bot.takeProfit !== undefined ? Number(bot.takeProfit) : 0,
      stopLose: bot.stopLose !== undefined ? Number(bot.stopLose) : 0,
    }));

    try {
      const response = await axios.put(`/update-task/${task._id}`, {
        name: newName,
        saveOrders: newSaveOrders,
        status: newStatus,
        comment: newComment,
        botBalance: Number(newBotBalance),
        symbol: formattedSymbol,
        leverage: Number(newLeverage),
        maxMargin: Number(newMaxMargin),
        liquidationRate: Number(newLiquidationRate),
        maintenance: Number(newMaintenance),
        bots: formattedBots, 
        chooseSymbolPeriods: chooseSymbolPeriods, 
      });

      console.log(`Task успешно обновлен: ${response.data.message}`);
      updateTasks();
      toggleModal(); 
      onSave?.(true); 
    } catch (error) {
      console.log('Ошибка при обновлении task');
      onSave?.(false); 
    }
  };

  const handleRemoveBot = (index: number) => {
    setBotToDeleteIndex(index);
    toggleModalDeleteBot();
  };

  const toggleModalBot = () => {
    setIsModalBot(!isModalBot);
  };

  const handleEditBot = (bot: Bot) => {
    setEditingBot(bot);
    setIsModalBot(true);
  };

  return (
    <>
      <form onSubmit={handleUpdateTask}>
        <div className="mb-4">
          <label className="mb-2.5 block text-black dark:text-white">
            Name
          </label>
          <input
            type="text"
            placeholder="Name task"
            value={newName}
            onChange={handleNameChange}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="mb-4">
          <MultiSelect
            options={optionsSymbol}
            defaultValue={newSymbol}
            onChange={handleSelectChange}
          />
        </div>

        {periods?.length > 0 && (
          <MultipleChoose
            periods={periods}
            selectedPeriods={chooseSymbolPeriods}
            onSelect={handleSelectPeriod}
            onDeselect={handleDeselectPeriod}
          />
        )}

        <div className="mb-4 flex gap-3 items-center justify-between">
          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Bot balance
            </label>
            <input
              type="number"
              placeholder="Balance"
              value={newBotBalance === '' ? '' : newBotBalance}
              onChange={handleBotBalanceChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>

          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Leverage
            </label>
            <input
              type="number"
              placeholder="Leverage"
              value={newLeverage === '' ? '' : newLeverage}
              onChange={handleLeverageChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>
        </div>

        <div className="mb-4 flex gap-3 items-center justify-between">
          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Max margin
            </label>
            <input
              type="number"
              placeholder="Max margin"
              value={newMaxMargin === '' ? '' : newMaxMargin}
              onChange={handleMaxMarginChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>

          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Liquidation Rate
            </label>
            <input
              type="number"
              placeholder="Liquidation Rate"
              value={newLiquidationRate === '' ? '' : newLiquidationRate}
              onChange={handleLiquidationRateChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>
        </div>

        <div className="mb-4 flex gap-3 items-center justify-between">
          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Maintenance
            </label>
            <input
              type="number"
              placeholder="Maintenance"
              value={newMaintenance === '' ? '' : newMaintenance}
              onChange={handleMaintenanceChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>

          <div>
            <label className="mb-2.5 block text-black dark:text-white">
              Status
            </label>
            <input
              type="text"
              placeholder="Status"
              value={newStatus}
              onChange={handleStatusChange}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="mb-2.5 block text-black dark:text-white">
            Comment
          </label>
          <input
            type="text"
            placeholder="Comment"
            value={newComment}
            onChange={handleCommentChange}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="mb-4 flex gap-3 items-center justify-between">
          <span 
            className="cursor-pointer inline-flex items-center justify-center gap-2.5 rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
            onClick={() => {
              setEditingBot(undefined);
              toggleModalBot();
            }}
            >
            <BsRobot />
            Add bot
          </span>

          <div className='flex gap-3 items-center'>
            <p>Save Orders</p>
            <Switcher 
              isActive={newSaveOrders} 
              onChange={handleSaveOrdersChange} 
              id="save-orders" 
            />
          </div>

          <ModalCenter
            isOpen={isModalBot}
            toggleModal={toggleModalBot}
            content={
              <SettingBot toggleModal={toggleModalBot} setBots={setNewBots} bot={editingBot} /> 
            }
          />
        </div>

        {/* Отображение ботов */}
        <div className="mt-4">
          {newBots?.map((bot, index) => (
            <div key={index} className="flex items-center justify-between p-4 border rounded-md mb-2">
              <div>
                <p className="font-bold">{bot.strategy}</p>
              </div>
              <div className="flex items-center gap-2">
                <span onClick={() => handleRemoveBot(index)} className="text-red-500 cursor-pointer">
                  <BsTrash />
                </span>

                <ModalCenter
                  isOpen={isModalDeleteBot && botToDeleteIndex === index}
                  toggleModal={toggleModalDeleteBot}
                  content={
                    <DeleteBot toggleModal={toggleModalDeleteBot} index={index} onDelete={(index) => {
                      const updatedBots = [...newBots];
                      updatedBots.splice(index, 1);
                      setNewBots(updatedBots);
                    }} />
                  }
                />

                <span onClick={() => handleEditBot(bot)} className="text-blue-500 cursor-pointer">
                  <BsPencil />
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <button
            type="submit"
            className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Edit task
          </button>
          <button
            type="button"
            onClick={() => {
              toggleModal();
            }}
            className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export default EditTask;