import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import Dashboard from './pages/Dashboard/Dashboard';
import ErrorPage from './pages/Error/Error'; 
import ApiKeys from './pages/ApiKeys/ApiKeys'
import DefaultLayout from './layout/DefaultLayout';
import SignInLayout from './layout/SignInLayout';
import Settings from './pages/Settings/Settings';
import BotsAll from './pages/Bots/BotsAll';
import Orders from './pages/Orders/Orders';
import Test from './pages/Test/Test';
import Users from './pages/Users/Users';
import Profile from './pages/Profile/Profile';
import Benchmark from './pages/Benchmark/Benchmark';
import { UserProvider } from './context/UserContext'; 

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;

    console.log("app", user?.isAuthenticated);
    if (!user?.isAuthenticated && pathname !== '/') {
      navigate('/'); 
    } else if (user?.isAuthenticated && pathname === '/') {
      navigate('/benchmark'); 
    }
  }, [pathname, navigate]);

  return loading ? (
    <Loader />
  ) : (
    <UserProvider>
      <Routes>
        <Route
          path="/"
          element={
            <SignInLayout>
              <PageTitle title="Sign in" />
              <SignIn />
            </SignInLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <DefaultLayout>
              <PageTitle title="Dashboard" />
              <Dashboard />
            </DefaultLayout>
          }
        />
        <Route
          path="/benchmark"
          element={
            <DefaultLayout>
              <PageTitle title="Benchmark" />
              <Benchmark />
            </DefaultLayout>
          }
        />
        <Route
          path="/test"
          element={
            <DefaultLayout>
              <PageTitle title="Dashboard" />
              <Test />
            </DefaultLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <DefaultLayout>
              <PageTitle title="Profile" />
              <Profile />
            </DefaultLayout>
          }
        />
        <Route
          path="/users"
          element={
            <DefaultLayout>
              <PageTitle title="Users" />
              <Users />
            </DefaultLayout>
          }
        />
        <Route
          path="/bots-all"
          element={
            <DefaultLayout>
              <PageTitle title="Bots All" />
              <BotsAll />
            </DefaultLayout>
          }
        />
        <Route
          path="/bots-test"
          element={
            <DefaultLayout>
              <PageTitle title="Bots Test" />
              <Dashboard />
            </DefaultLayout>
          }
        />
        <Route
          path="/bots-investors"
          element={
            <DefaultLayout>
              <PageTitle title="Bots Investors" />
              <Dashboard />
            </DefaultLayout>
          }
        />
        <Route
          path="/bots-btc"
          element={
            <DefaultLayout>
              <PageTitle title="Bots BTC" />
              <Dashboard />
            </DefaultLayout>
          }
        />
        <Route 
          path="/settings"
          element={
            <DefaultLayout>
              <PageTitle title="Settings" />
              <Settings />
            </DefaultLayout>
          }
        />
        <Route 
          path="/api-keys"
          element={
            <DefaultLayout>
              <PageTitle title="Api keys" />
              <ApiKeys />
            </DefaultLayout>
          }
        />
        <Route 
          path="/orders"
          element={
            <DefaultLayout>
              <PageTitle title="Orders" />
              <Orders />
            </DefaultLayout>
          }
        />
        <Route
          path="/error"
          element={
            <DefaultLayout>
              <PageTitle title="Error" />
              <ErrorPage />
            </DefaultLayout>
          }
        />
        <Route
          path="*"
          element={
            <DefaultLayout>
              <PageTitle title="Error" />
              <ErrorPage />
            </DefaultLayout>
          }
        />
      </Routes>
    </UserProvider>
  );
}

export default App;