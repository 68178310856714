import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/logo/logo.svg';
import { validateEmail, validatePassword } from '../../utils/validations';
import InputEmail from '../../components/Forms/Inputs/InputEmail';
import InputPassword from '../../components/Forms/Inputs/InputPassword';
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import { UserContext } from '../../context/UserContext';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorForm, setErrorForm] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    if (errorForm) {
      setErrorForm(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);

    if (errorForm) {
      setErrorForm(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post('/auth', {
        email,
        password,
      });

      console.log(response.data); 

      if (response.status === 200) {
        toast.success('Successfully login');

        const userData = {
          id: response.data.id, 
          email: response.data.email, 
          isAuthenticated: true, 
        };
        setUser(userData);

        navigate('/benchmark');
      } else {
        setErrorForm(true);
      }
    } catch (error) {
      setErrorForm(true);
      toast.error('Failed login');
    }
  };

  useEffect(() => {
    const isValidEmail = validateEmail(email);
    const isValidPassword = validatePassword(password);
    setIsFormValid(isValidEmail && isValidPassword);
  }, [email, password]);

  return (
    <div className="rounded-lg border shadow-default border-strokedark bg-boxdark w-full max-w-lg mx-4 sm:mx-auto">
      <Toaster position="bottom-center" richColors />
      <div className="flex flex-wrap items-center">
        <div className="w-full border-strokedark p-6 sm:p-11.5">
          <div className='mb-4 flex items-center justify-center'>
            <img src={Logo} alt="Logo" />
          </div>

          <form onSubmit={handleSubmit}>
            <InputEmail value={email} onChange={handleEmailChange} validate={validateEmail} />
            <InputPassword 
              value={password} 
              onChange={handlePasswordChange} 
              validate={validatePassword}
              errorForm={errorForm} />

            {errorForm && <p className="text-red-500 mb-4">Incorrect email or password</p>}

            <div className="mb-5">
              <button
                type="submit"
                className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${
                  !isFormValid ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!isFormValid}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;