import { BsFillTrashFill, BsFillPencilFill, BsFillSendFill } from "react-icons/bs";
import { BOT } from '../../types/bot';
import React, { useState } from 'react';
import ModalCenter from '../Modals/ModalCenter';
import ModalSideRight from "../Modals/ModalSideRight";
import ContentModalAddKey from "../../pages/ApiKeys/ContentModalAddKey";
import ContentModalEdit from "../../pages/ApiKeys/ContentModalEdit";
import ContentModalConfirm from '../../pages/ApiKeys/ContentModalConfirm'
import ContentModalSend from '../../pages/ApiKeys/ContentModalSend'
import Switcher from "../Switchers/Switcher";

const botData: BOT[] = [
  {
    name: "dfjns'ldnf'sldknf'lsnkjknfkjsbvjdffvdjfvn",
    visitors: 3.5,
    revenues: '5,768',
    sales: 590,
    conversion: 4.8,
    isActive: true
  },
  {
    name: "b;kdbfs;djf'sldfjns'ldn'vndfmdf",
    visitors: 2.2,
    revenues: '4,635',
    sales: 467,
    conversion: 4.3,
    isActive: false
  },
  {
    name: "eytuyoipiyhib43436ljvl-997gfc",
    visitors: 2.1,
    revenues: '4,290',
    sales: 420,
    conversion: 3.7,
    isActive: true
  },
  {
    name: "khbrobjlbn'lxznxb;zddnbe5645yjrrgbfgg",
    visitors: 1.5,
    revenues: '3,580',
    sales: 389,
    conversion: 2.5,
    isActive: true
  },
  {
    name: "hvclhgvlgvlgvlui.,.3453bndbklvnz",
    visitors: 3.5,
    revenues: '6,768',
    sales: 390,
    conversion: 4.2,
    isActive: false
  },
];

const TableBotsAll = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalSend, setIsModalSend] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleModalSide = () => setIsModalSideOpen(!isModalSideOpen);
  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalSend = () => setIsModalSend(!isModalSend);

  const [switchersState, setSwitchersState] = useState(
    botData.map(bot => bot.isActive)
  );

  const handleSwitcherChange = (index: number) => {
    const newSwitchersState = [...switchersState];
    newSwitchersState[index] = !newSwitchersState[index];
    setSwitchersState(newSwitchersState);
  };

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="mb-4 flex justify-between items-center">
        <button 
          onClick={toggleModalSide}
          className="flex items-center gap-2 rounded bg-primary py-2 px-4.5 font-medium text-white hover:bg-opacity-90 modal-show-button">
          <svg 
            className="fill-current" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
              <path d="M15 7H9V1C9 0.4 8.6 0 8 0C7.4 0 7 0.4 7 1V7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H7V15C7 15.6 7.4 16 8 16C8.6 16 9 15.6 9 15V9H15C15.6 9 16 8.6 16 8C16 7.4 15.6 7 15 7Z" fill=""></path>
              </svg>
            Refresh
        </button>
        
        <ModalSideRight
          isOpen={isModalSideOpen}
          toggleModal={toggleModalSide}
          title="NEW KEY"
          content={ContentModalAddKey}
        />
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-5">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              BOT
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              API
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              POSITION
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              ACTION
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              SHOW
            </h5>
          </div>
        </div>

        {botData.map((bot, key) => (
          <div
            className={`grid grid-cols-3 sm:grid-cols-5 ${
              key === botData.length - 1
                ? ''
                : 'border-b border-stroke dark:border-strokedark'
            }`}
            key={key}
          >
            <div className="flex items-center gap-3 p-2.5 xl:p-5">
              <p
                className="hidden text-black dark:text-white sm:block overflow-hidden whitespace-nowrap text-ellipsis"
                title={bot.name}
              >
                {bot.name}
              </p>
              <Switcher 
                isActive={switchersState[key]} 
                onChange={() => handleSwitcherChange(key)} 
                id={`toggle3-${key}`} // Уникальный id для каждого Switcher
              />
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-black dark:text-white">{bot.visitors}K</p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-meta-3">${bot.revenues}</p>
            </div>

            <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
              <p className="text-meta-5">{bot.conversion}%</p>
            </div>

            <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
              <span className="actions flex grid-cols-2 gap-4">
                <BsFillTrashFill
                  className="delete-btn cursor-pointer"
                  onClick={toggleModal}
                 />

                <ModalCenter
                  isOpen={isModalOpen}
                  toggleModal={toggleModal}
                  content={<ContentModalConfirm toggleModal={toggleModal} />}
                />
                
                <BsFillPencilFill
                  className="edit-btn cursor-pointer"
                  onClick={toggleModalEdit} />

                <ModalSideRight
                  isOpen={isModalEditOpen}
                  toggleModal={toggleModalEdit}
                  title="EDIT"
                  content={ContentModalEdit}
                />

                <BsFillSendFill 
                  className="send-btn cursor-pointer"
                  onClick={toggleModalSend} />

                <ModalCenter
                  isOpen={isModalSend}
                  toggleModal={toggleModalSend}
                  content={<ContentModalSend toggleModal={toggleModalSend} />}
                />
                
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableBotsAll;