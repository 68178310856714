import React, { useState } from 'react'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { initialData } from '../../data/test'
import { DragDropContext, DropResult } from '@hello-pangea/dnd'
import TestColumn from './TestColumn'

const Test: React.FC = () => {
  const [data, setData] = useState(initialData)

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newData = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newData);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setData(newData);
  };

  return(
    <>
      <Breadcrumb pageName='Page for testing' />

      <DragDropContext onDragEnd={handleDragEnd}>
        {
          data.columnOrder.map(item => {
            const column = data.columns[item];
            const tasks = column.taskIds.map(taskId => data.tasks[taskId]);

            return <TestColumn key={column.id} data={column} tasks={tasks} />;
          })
        }
      </DragDropContext>
    </>
  )
}

export default Test