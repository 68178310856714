import React, { useState, useEffect } from 'react';
import MultiSelect from "../../components/Forms/Select/MultiSelect";
import axios from 'axios';
import Switcher from "../../components/Switchers/Switcher";
import { BenchmarkPeriod, DownloadItem } from '../../types/period';
import { Toaster, toast } from 'sonner';
import MultipleChoose from '../../components/Forms/Select/MultipleChoose';
import { BsRobot, BsTrash, BsPencil } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import SettingBot from './SettingBot/SettingBot';
import InputField from '../../components/Forms/Inputs/InputField';
import { Bot } from '../../types/task';
import DeleteBot from './SettingBot/DeleteBot';

interface AddTaskProps {
  toggleModal: () => void;
  updateTasks?: () => void;
  onSave?: (result: boolean) => void;
}

const AddTask: React.FC<AddTaskProps> = ({ toggleModal, updateTasks, onSave }) => {
  const [name, setName] = useState('');
  const [saveOrders, setSaveOrders] = useState(false);
  const [botBalance, setBotBalance] = useState<number | ''>('');
  const [symbol, setSymbol] = useState<string[]>([]);
  const [leverage, setLeverage] = useState<number | ''>('');
  const [maxMargin, setMaxMargin] = useState<number | ''>('');
  const [liquidationRate, setLiquidationRate] = useState<number | ''>('');
  const [maintenance, setMaintenance] = useState<number | ''>('');
  const [optionsSymbol, setOptionsSymbol] = useState<{ value: string; label: string }[]>([]);
  const [periods, setPeriods] = useState<DownloadItem[]>([]);
  const [chooseSymbolPeriods, setChooseSymbolPeriods] = useState<DownloadItem[]>([]);
  const [isModalBot, setIsModalBot] = useState(false);
  const [bots, setBots] = useState<Bot[]>([]); 
  const [editingBot, setEditingBot] = useState<Bot | undefined>(undefined); 
  const [isModalDeleteBot, setIsModalDeleteBot] = useState(false);
  const [botToDeleteIndex, setBotToDeleteIndex] = useState<number | null>(null);

  const toggleModalBot = () => {
    setIsModalBot(!isModalBot);
  };

  const toggleModalDeleteBot = () => {
    setIsModalDeleteBot(!isModalDeleteBot);
  };

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await axios.get<{ name: string }[]>('/get-benchmark-symbols');
        const symbols = response.data.map((symbol) => ({
          value: symbol.name,
          label: symbol.name,
        }));
        setOptionsSymbol(symbols);
      } catch (error) {
        console.error('Ошибка при получении символов:', error);
        toast.error('Ошибка при получении символов');
      }
    };

    fetchSymbols();
  }, []);

  const handleSelectChange = (values: string[]) => {
    setSymbol(values);
    if (values.length > 0) {
      fetchPeriods(values[0]);
    } else {
      setPeriods([]);
    }
  };

  const fetchPeriods = async (symbol: string) => {
    try {
      const response = await axios.get<BenchmarkPeriod>(`/get-symbol-periods/${symbol}`);
      setPeriods(response.data.symbolPeriods);
      console.log(response.data);
    } catch (error) {
      console.error('Ошибка при получении периодов:', error);
      toast.error('Ошибка при получении периодов');
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSaveOrdersChange = () => {
    setSaveOrders(!saveOrders);
  };

  const handleBotBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBotBalance(value === '' ? '' : parseInt(value, 10));
  };

  const handleLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLeverage(value === '' ? '' : parseInt(value, 10));
  };

  const handleMaxMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxMargin(value === '' ? '' : parseInt(value, 10));
  };

  const handleLiquidationRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLiquidationRate(value === '' ? '' : parseFloat(value));
  };

  const handleMaintenanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaintenance(value === '' ? '' : parseInt(value, 10));
  };

  const handleSelectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods([...chooseSymbolPeriods, period]);
  };

  const handleDeselectPeriod = (period: DownloadItem) => {
    setChooseSymbolPeriods(chooseSymbolPeriods.filter(p => p.periodId !== period.periodId));
  };

  const handleAddTask = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name === '' || botBalance === '' || leverage === '' || symbol.length === 0 || maxMargin === '' || liquidationRate === '' || maintenance === '') {
      toast.error('Пожалуйста, заполните все поля');
      return;
    }

    const formattedSymbol = symbol.map(value => ({ name: value }));

    const taskData = {
      name,
      saveOrders,
      botBalance: Number(botBalance),
      symbol: formattedSymbol,
      leverage: Number(leverage),
      maxMargin: Number(maxMargin),
      liquidationRate: Number(liquidationRate),
      maintenance: Number(maintenance),
      chooseSymbolPeriods,
      bots: bots.map(bot => ({
        ...bot,
        initUsdt: Number(bot.initUsdt),
        openProcentMax: bot.openProcentMax !== undefined ? Number(bot.openProcentMax) : 0,
        stopProcent: bot.stopProcent !== undefined ? Number(bot.stopProcent) : 0,
        takeProfit: bot.takeProfit !== undefined ? Number(bot.takeProfit) : 0,
        stopLose: bot.stopLose !== undefined ? Number(bot.stopLose) : 0,
      })),
    };

    try {
      const response = await axios.post('/create-task', taskData);
      console.log(`Task успешно добавленa с ID: ${response.data.insertedID}`);
      toggleModal();
      onSave?.(true);
    } catch (error) {
      console.log('Ошибка при добавлении task');
      onSave?.(false);
    }
  };

  const handleRemoveBot = (index: number) => {
    setBotToDeleteIndex(index);
    toggleModalDeleteBot();
  };

  const handleEditBot = (bot: Bot) => {
    setEditingBot(bot);
    setIsModalBot(true);
  };

  return (
    <>
      <form onSubmit={handleAddTask} className="flex flex-col h-full">
        <div className="flex-grow">
          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Name task"
            className="mb-4"
          />

          <div className="mb-4">
            <MultiSelect
              options={optionsSymbol}
              onChange={handleSelectChange}
            />
          </div>

          {periods?.length > 0 && (
            <MultipleChoose
              periods={periods}
              selectedPeriods={chooseSymbolPeriods}
              onSelect={handleSelectPeriod}
              onDeselect={handleDeselectPeriod}
            />
          )}

          <div className="mb-4 flex gap-3 items-center justify-between">
            <InputField
              label="Bot balance"
              value={botBalance === '' ? '' : botBalance}
              onChange={handleBotBalanceChange}
              placeholder="Balance"
              type="number"
            />

            <InputField
              label="Leverage"
              value={leverage === '' ? '' : leverage}
              onChange={handleLeverageChange}
              placeholder="Leverage"
              type="number"
            />
          </div>

          <div className="mb-4 flex gap-3 items-center justify-between">
            <InputField
              label="Max margin"
              value={maxMargin === '' ? '' : maxMargin}
              onChange={handleMaxMarginChange}
              placeholder="Max margin"
              type="number"
            />

            <InputField
              label="Liquidation Rate"
              value={liquidationRate === '' ? '' : liquidationRate}
              onChange={handleLiquidationRateChange}
              placeholder="Liquidation Rate"
              type="number"
            />
          </div>

          <div className="mb-4 flex gap-3 items-end justify-between">
            <InputField
              label="Maintenance"
              value={maintenance === '' ? '' : maintenance}
              onChange={handleMaintenanceChange}
              placeholder="Maintenance"
              type="number"
            />

            <div className='flex gap-3 items-center mb-3'>
              <p className='text-md text-black dark:text-white font-medium'>Save Orders</p>
              <Switcher 
                isActive={saveOrders} 
                onChange={handleSaveOrdersChange} 
                id="save-orders" 
              />
            </div>
          </div>

          <span 
            className="mt-4 cursor-pointer inline-flex items-center justify-center gap-2.5 rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
            onClick={() => {
              setEditingBot(undefined);
              toggleModalBot();
            }}
            >
            <BsRobot />
            Add bot
          </span>

          <ModalCenter
            isOpen={isModalBot}
            toggleModal={toggleModalBot}
            content={
              <SettingBot toggleModal={toggleModalBot} setBots={setBots} bot={editingBot} /> 
            }
          />

          {/* Отображение ботов */}
          <div className="mt-4">
            {bots.map((bot, index) => (
              <div key={index} className="flex items-center justify-between p-4 border rounded-md mb-2">
                <div className='flex gap-2'>
                  <p className="font-bold text-lg">{bot.strategy}</p>
                  <span className="inline-flex rounded-full bg-[#3BA2B8] py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90">{bot.timeFrame}</span>
                </div>
                <div className="flex items-center gap-2">
                  <span onClick={() => handleRemoveBot(index)} className="text-red-500 cursor-pointer">
                    <BsTrash />
                  </span>

                  <ModalCenter
                    isOpen={isModalDeleteBot && botToDeleteIndex === index}
                    toggleModal={toggleModalDeleteBot}
                    content={
                      <DeleteBot toggleModal={toggleModalDeleteBot} index={index} onDelete={(index) => {
                        const newBots = [...bots];
                        newBots.splice(index, 1);
                        setBots(newBots);
                      }} />
                    }
                  />

                  <span onClick={() => handleEditBot(bot)} className="text-blue-500 cursor-pointer">
                    <BsPencil />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <button
            type="submit"
            className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Add task
          </button>
          <button
            type="button"
            onClick={() => {
              toggleModal();
            }}
            className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export default AddTask;