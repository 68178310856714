import React from 'react'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import TableOrders from '../../components/Tables/TableOrders'

const Orders: React.FC = () => {
  return(
    <>
      <Breadcrumb pageName="Orders" />
      <TableOrders />
    </>
  )
}

export default Orders