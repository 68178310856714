import React, { useState } from 'react';
import { BsFillPencilFill, BsChevronDown, BsChevronUp, BsFillTrashFill } from "react-icons/bs";
import ModalSideRight from '../Modals/ModalSideRight';
import ModalCenter from '../Modals/ModalCenter';
import EditUser from '../../pages/Users/EditUser';
import DeleteUser from '../../pages/Users/DeleteUser';
import { USER } from '../../types/user';
import { Toaster, toast } from 'sonner';

interface UsersProps {
  users: USER[];
  updateUsers: () => void;
}

const TableUsers: React.FC<UsersProps> = ({ users, updateUsers }) => {
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [expandedComments, setExpandedComments] = useState<{ [key: number]: boolean }>({});
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<USER | null>(null);

  const toggleModalDelete = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const toggleModalEdit = () => {
    setIsModalEditOpen(!isModalEditOpen);
  };

  const toggleComment = (index: number) => {
    setExpandedComments(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleEditUser = (user: USER) => {
    setSelectedUser(user);
    toggleModalEdit();
  };

  const handleDeleteUser = (user: USER) => {
    setSelectedUser(user);
    toggleModalDelete();
  };

  const handleEditSave = (result: boolean) => {
    if (result) {
      toast.success('User added successfully')
    } else {
      toast.error('Failed to edit user')
    }
  };

  const handleDeleteConfirm = (result: boolean) => {
    if (result) {
      toast.success('User delete successfully')
    } else {
      toast.error('Failed to delete user')
    }
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-x-auto rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="flex flex-col">
          <div className="flex gap-2 rounded-sm bg-gray-2 dark:bg-meta-4">
            <div className="p-2.5 xl:p-5 text-center w-15 min-w-[60px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                #
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5 w-25 min-w-[100px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                NAME
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5 w-50 min-w-[200px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                EMAIL
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5 w-35 min-w-[140px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                PREMISSION
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5 flex-1 min-w-[200px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                COMMENT
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5 w-30 min-w-[120px]">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                ACTION
              </h5>
            </div>
          </div>

          {users.map((user, key) => (
            <div
              className={`flex gap-2 ${
                key === users.length - 1
                  ? ''
                  : 'border-b border-stroke dark:border-strokedark'
              }`}
              key={key}
            >
              <div className="flex items-start justify-center gap-3 p-2.5 xl:p-5 text-center w-15 min-w-[60px]">
                <p className="text-black dark:text-white overflow-hidden whitespace-nowrap text-ellipsis">
                  {key + 1}
                </p>
              </div>

              <div className="flex items-start justify-center p-2.5 xl:p-5 w-25 min-w-[100px]">
                <p className="text-black dark:text-white">{user.name}</p>
              </div>

              <div className="flex items-start justify-center p-2.5 xl:p-5 w-50 min-w-[200px]">
                <p className="text-meta-3">{user.email}</p>
              </div>

              <div className="items-start justify-center p-2.5 sm:flex xl:p-5 w-35 min-w-[140px]">
                {user.permission === 'Admin' && <p className="inline-flex rounded-full bg-primary py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90">{user.permission}</p>}
                {user.permission === 'Moderator' && <p className="inline-flex rounded-full bg-[#637381] py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90">{user.permission}</p>}
              </div>

              <div className="items-center justify-center p-2.5 sm:flex xl:p-5 flex-1 min-w-[200px]">
                <div className="relative w-full flex gap-3 items-center justify-between">
                  <p className={`w-full text-center text-meta-5 ${expandedComments[key] ? '' : 'line-clamp-1'}`}>
                    {user.comment}
                  </p>
                  {user.comment.length > 50 && (
                    <button
                      className="absolute top-1 -right-4 text-gray-500 hover:text-gray-700"
                      onClick={() => toggleComment(key)}
                    >
                      {expandedComments[key] ? <BsChevronUp /> : <BsChevronDown />}
                    </button>
                  )}
                </div>
              </div>

              <div className="gap-4 items-start justify-center p-2.5 sm:flex xl:p-5 w-30 min-w-[120px]">
                <BsFillPencilFill
                  className="edit-btn cursor-pointer"
                  onClick={() => handleEditUser(user)} />

                <ModalSideRight
                  isOpen={isModalEditOpen}
                  toggleModal={toggleModalEdit}
                  title="EDIT USER"
                  content={
                    selectedUser && (
                      <EditUser 
                        toggleModal={toggleModalEdit} 
                        idUser={selectedUser._id}
                        name={selectedUser.name}
                        email={selectedUser.email}
                        permission={selectedUser.permission}
                        password={selectedUser.password}
                        comment={selectedUser.comment}
                        updateUsers={updateUsers}
                        onSave={handleEditSave}
                      />
                    )
                  } />

                <BsFillTrashFill
                  className="delete-btn cursor-pointer"
                  onClick={() => handleDeleteUser(user)}
                />

                <ModalCenter
                  isOpen={isModalDeleteOpen}
                  toggleModal={toggleModalDelete}
                  content={
                    selectedUser && (
                      <DeleteUser 
                        toggleModal={toggleModalDelete} 
                        idUser={selectedUser._id}
                        updateUsers={updateUsers}
                        onDelete={handleDeleteConfirm} 
                      />
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TableUsers;