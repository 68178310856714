import React, { useState, useEffect } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import InputEmail from "../../components/Forms/Inputs/InputEmail";
import InputPassword from "../../components/Forms/Inputs/InputPassword";
import { validateEmail, validatePassword } from '../../utils/validations';
import axios from 'axios';

const optionsStatus = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Moderator', label: 'Moderator' },
];

interface EditUserProps {
  toggleModal: () => void;
  idUser: string;
  name: string;
  permission: string;
  email: string;
  password: string;
  comment: string;
  updateUsers: () => void;
  onSave?: (result: boolean) => void; // Добавляем функцию обратного вызова
}

const EditUser: React.FC<EditUserProps> = ({ 
  toggleModal, 
  idUser, 
  name, 
  permission,
  email,
  password,
  comment,
  updateUsers,
  onSave,
}) => {
  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const [newPassword, setNewPassword] = useState(password);
  const [newPermission, setNewPermission] = useState(permission);
  const [newComment, setNewComment] = useState(comment);

  useEffect(() => {
    setNewName(name);
    setNewEmail(email);
    setNewPassword(password);
    setNewPermission(permission);
    setNewComment(comment);
  }, [idUser, name, email, password, permission, comment]);

  const handleSelectChange = (value: string) => {
    setNewPermission(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.put(`/update-user/${idUser}`, {
        name: newName,
        email: newEmail,
        permission: newPermission,
        comment: newComment,
        password: newPassword
      });

      console.log(`Пользователь успешно обновлен: ${response.data.message}`);
      updateUsers();
      toggleModal(); // Закрываем модальное окно после успешного обновления
      onSave?.(true); // Вызываем функцию обратного вызова с true
    } catch (error) {
      console.log('Ошибка при обновлении пользователя');
      onSave?.(false); // Вызываем функцию обратного вызова с false
    }
  };

  return (
    <>
      <form onSubmit={handleUpdateUser}>
        {/* <div className="mb-4">
          <SimpleSelect
            label="Permissions"
            options={optionsStatus}
            onChange={handleSelectChange}
            className="w-full mb-4"
          />
        </div> */}

        <div className="mb-4">
          <label className="mb-2.5 block text-black dark:text-white">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            value={newName}
            onChange={handleNameChange}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="mb-4">
          <InputEmail value={newEmail} onChange={handleEmailChange} validate={validateEmail} />
        </div>

        <div className="mb-4">
          <InputPassword 
            value={newPassword} 
            onChange={handlePasswordChange} 
            validate={validatePassword} />
        </div>

        <div className="mb-4">
          <label className="mb-3 block text-black dark:text-white">
            Comment
          </label>
          <textarea
            rows={6}
            placeholder="Default textarea"
            value={newComment}
            onChange={handleCommentChange}
            className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          ></textarea>
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <button
            type="submit"
            className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() => {
              toggleModal();
              onSave?.(false); 
            }}
            className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export default EditUser;