import React, { useRef } from 'react';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import './ModalCenter.css'; // Создайте этот файл для стилей анимации

interface ModalCenterProps {
  isOpen: boolean;
  toggleModal: () => void;
  content: React.ReactNode;
}

const ModalCenter = ({ isOpen, toggleModal, content }: ModalCenterProps) => {
  const nodeRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="Confirm Modal"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:border-strokedark dark:bg-boxdark rounded-lg shadow-lg p-4 md:p-5 max-w-md w-full"
      overlayClassName="fixed top-0 right-0 left-0 z-9999 flex justify-center items-center w-full h-full bg-black transition-opacity bg-opacity-25"
      appElement={document.getElementById('root') || undefined}
    >
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className="modal-content">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={toggleModal}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="text-center">
            {content}
          </div>
        </div>
      </CSSTransition>
    </Modal>
  );
};

export default ModalCenter;