import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange }) => {
  return (
    <ReactPaginate
      previousLabel={'previous'}
      nextLabel={'next'}
      breakLabel={'...'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={'flex justify-center mt-4'}
      activeClassName={'bg-blue-500 text-white'}
      pageClassName={'mx-1 px-3 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      previousClassName={'mx-1 px-3 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      nextClassName={'mx-1 px-3 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      breakClassName={'mx-1 px-3 py-2 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300'}
      disabledClassName={'opacity-50 cursor-not-allowed'}
    />
  );
};

export default Pagination;