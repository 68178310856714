// src/components/Tables/TableOrders.tsx
import React, { useState, useEffect } from 'react';
import { ORDER } from '../../types/order';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

const orderData: ORDER[] = [
  {
    id: 1,
    symbol: "BTCUSDT",
    price: "63796,4000000",
    qtyCoin: "0,078",
    qtyUsdt: "$4,976,28",
    side: "SELL",
    positionSide: "LONG",
    clientOrder: "PROFIT_LONG-1",
    date: "11:43:58 25.09.2024"
  },
  {
    id: 2,
    symbol: "BTCUSDT",
    price: "63796,4000000",
    qtyCoin: "0,078",
    qtyUsdt: "$4,976,28",
    side: "SELL",
    positionSide: "LONG",
    clientOrder: "PROFIT_LONG-1",
    date: "11:43:58 25.09.2024"
  },
];

const TableOrders: React.FC = () => {
  const [orders, setOrders] = useState<ORDER[]>(() => {
    const savedOrders = localStorage.getItem('orders');
    return savedOrders ? JSON.parse(savedOrders) : orderData;
  });

  useEffect(() => {
    localStorage.setItem('orders', JSON.stringify(orders));
  }, [orders]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(orders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrders(items);
  };

  return (
    <div className="overflow-x-auto rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="min-w-max">
        <div className="grid grid-cols-8 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-8">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              SYMBOL
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              PRICE
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              QTY COIN
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              QTY USDT
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              SIDE
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              POSITION SIDE
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              CLIENT ORDER
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base whitespace-nowrap">
              DATE
            </h5>
          </div>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="orders">
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {orders.map((order, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                      <div
                        className={`grid grid-cols-8 sm:grid-cols-8 ${
                          index === orders.length - 1
                            ? ''
                            : 'border-b border-stroke dark:border-strokedark'
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="flex items-center justify-center p-2.5 xl:p-5">
                          <p className="text-black dark:text-white">{order.symbol}</p>
                        </div>

                        <div className="flex items-center justify-center p-2.5 xl:p-5">
                          <p className="text-black dark:text-white">{order.price}</p>
                        </div>

                        <div className="flex items-center justify-center p-2.5 xl:p-5">
                          <p className="text-meta-3">${order.qtyCoin}</p>
                        </div>

                        <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
                          <p className="text-meta-5">{order.qtyUsdt}</p>
                        </div>

                        <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
                          <p className="text-meta-5">{order.side}</p>
                        </div>

                        <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
                          <p className="text-meta-5">{order.positionSide}</p>
                        </div>

                        <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
                          <p className="text-meta-5 whitespace-nowrap">{order.clientOrder}</p>
                        </div>

                        <div className="items-center justify-center p-2.5 sm:flex xl:p-5">
                          <p className="text-meta-5">{order.date}</p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default TableOrders;