import SimpleSelect from "../../components/Forms/Select/SimpleSelect";


const handleSelectChange = (value: string) => {
  console.log('Selected value:', value);
};

const optionsExchangers = [
  { value: 'Binance', label: 'Binance' },
  { value: 'Bitget', label: 'Bitget' },
  { value: 'OKX', label: 'OKX' },
];

const optionsMarket = [
  { value: 'Futures', label: 'Futures' },
  { value: 'Futures2', label: 'Futures2' },
  { value: 'Futures3', label: 'Futures3' },
];

const ContentModalAddKey = (
  <>
    <div className="flex-1">
      {/* <SimpleSelect
        label="Exchangers"
        options={optionsExchangers}
        onChange={handleSelectChange}
        className="w-full mb-4"
      />

      <SimpleSelect
        label="Market"
        options={optionsMarket}
        onChange={handleSelectChange}
        className="w-full mb-4"
      /> */}

      <div className="mb-4">
        <label className="mb-2.5 block text-black dark:text-white">
          Name api
        </label>
        <input
          type="text"
          placeholder="Name api"
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
      </div>

      <div className="mb-4">
        <label className="mb-2.5 block text-black dark:text-white">
          Sub Account ID
        </label>
        <input
          type="text"
          placeholder="Sub Account ID"
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
      </div>

      <div className="mb-4">
        <label className="mb-2.5 block text-black dark:text-white">
          Public Key
        </label>
        <input
          type="text"
          placeholder="Public Key"
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
      </div>

      <div className="mb-4">
        <label className="mb-2.5 block text-black dark:text-white">
          Secret Key
        </label>
        <input
          type="text"
          placeholder="Secret Key"
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
      </div>
    </div>

    <div className='flex items-center justify-between gap-4 mt-auto'>
      <button
        className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
      >
        Add key
      </button>
      <button
        className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
      >
        Close
      </button>
    </div>
  </>
)

export default ContentModalAddKey;