import React from 'react';
import Modal from 'react-modal';

interface ModalSideProps {
  isOpen: boolean;
  toggleModal: () => void;
  title: string;
  content: React.ReactNode;
}

const ModalSideRight = ({ isOpen, toggleModal, title, content }: ModalSideProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="Slide Over Modal"
      className={`fixed inset-0 overflow-hidden transition-transform duration-500 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-500 ease-in-out z-9999"
      appElement={document.getElementById('root') || undefined}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div className="pointer-events-auto relative w-screen max-w-md">
            <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
              <button
                type="button"
                className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={toggleModal}
              >
                <span className="absolute -inset-2.5"></span>
                <span className="sr-only">Close panel</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex h-full flex-col overflow-y-scroll bg-white dark:border-strokedark dark:bg-boxdark py-6 shadow-xl">
              <div className="px-4 sm:px-6">
                <h2 className="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
                  {title}
                </h2>
              </div>
              {/* content start */}
              <div className="relative mt-6 flex-1 px-4 sm:px-6 flex flex-col">
                {content}
              </div>
              {/* content end */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSideRight;
