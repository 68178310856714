import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import TableUsers from '../../components/Tables/TableUsers'
import ModalSideRight from '../../components/Modals/ModalSideRight'
import AddUser from './AddUser'
import axios from 'axios';
import Loader from '../../common/Loader/index'; 
import { USER } from '../../types/user'
import { Toaster, toast } from 'sonner';


const Users: React.FC = () => {
  const [users, setUsers] = useState<USER[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalAddUser, setIsModalAddUser] = useState(false);

  const toggleModalAddUser = () => {
    setIsModalAddUser(!isModalAddUser);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/get-users');
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('User added successfully')
    } else {
      toast.error('Failed to add user')
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb pageName='Users' />
      <button
        onClick={toggleModalAddUser}
        className='mb-4 flex items-center gap-2 rounded bg-primary py-2 px-4.5 font-medium text-white hover:bg-opacity-90 modal-show-button'
      >
        Add user
      </button>
      <ModalSideRight
        isOpen={isModalAddUser}
        toggleModal={toggleModalAddUser}
        title="ADD USER"
        content={<AddUser toggleModal={toggleModalAddUser} updateUsers={getUsers} onSave={handleSave} />}
      />
      <TableUsers users={users} updateUsers={getUsers} />
    </>
  );
};

export default Users;