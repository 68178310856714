import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DatePicker from '../../components/Forms/DatePicker';
import TableBotsAll from '../../components/Tables/TableBotsAll';

const BotsAll: React.FC = () => {
  return (
    <>
      <Breadcrumb pageName="Bots All" />

      <div className='flex items-center justify-between gap-4 mb-4 rounded-lg border border-stroke bg-white px-3 py-2.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
        <p>Выберите диапазон даты:</p>
        <div className='flex items-center justify-between gap-4'>
          <DatePicker />
          <p>to</p>
          <DatePicker />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12">
          <TableBotsAll />
        </div>
      </div>
    </>
  );
};

export default BotsAll;
