import SimpleSelect from "../../components/Forms/Select/SimpleSelect";


const handleSelectChange = (value: string) => {
  console.log('Selected value:', value);
};

const optionsAccount = [
  { value: 'Account1', label: 'Account1' },
  { value: 'Account2', label: 'Account2' },
  { value: 'Account3', label: 'Account3' },
];

interface ContentModalCenterProps {
  toggleModal: () => void;
}

const ContentModalSend: React.FC<ContentModalCenterProps> = ({ toggleModal }) => (
  <>
    <svg
      className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <p className="mb-2.5 text-black dark:text-white">Transfer from akk__59 (____)</p>
    {/* <SimpleSelect
        label="To account"
        options={optionsAccount}
        onChange={handleSelectChange}
        className="w-full mb-4"
      /> */}

    <div className="mb-4">
      <label className="mb-2.5 block text-black dark:text-white">
        Amount
      </label>
      <input
        type="text"
        placeholder="Amount"
        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
      />
    </div>

    <button
        className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
      >
        Transfer USDT
      </button>
  </>
)

export default ContentModalSend;