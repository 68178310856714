import React, { useState } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import InputEmail from "../../components/Forms/Inputs/InputEmail";
import InputPassword from "../../components/Forms/Inputs/InputPassword";
import { validateEmail, validatePassword } from '../../utils/validations';
import axios from 'axios';

interface AddUserProps {
  toggleModal: () => void;
  updateUsers: () => void;
  onSave?: (result: boolean) => void; // Добавляем функцию обратного вызова
}

const optionsPremission = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Moderator', label: 'Moderator' },
];

const AddUser: React.FC<AddUserProps> = ({ toggleModal, updateUsers, onSave }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [permission, setPermission] = useState('');
  const [comment, setComment] = useState('');

  const handleSelectChange = (value: string) => {
    setPermission(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAddUser = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post('/create-user', {
        name,
        email,
        permission,
        comment,
        password
      });

      console.log(`Пользователь успешно добавлен с ID: ${response.data.insertedID}`);
      updateUsers();
      toggleModal(); // Закрываем модальное окно после успешного добавления
      onSave?.(true); // Вызываем функцию обратного вызова с true
    } catch (error) {
      console.log('Ошибка при добавлении пользователя');
      onSave?.(false); // Вызываем функцию обратного вызова с false
    }
  };

  return (
    <>
      <form onSubmit={handleAddUser}>
        {/* <div className="mb-4">
          <SimpleSelect
            label="Permissions"
            options={optionsPremission}
            onChange={handleSelectChange}
            className="w-full mb-4"
          />
        </div> */}

        <div className="mb-4">
          <label className="mb-2.5 block text-black dark:text-white">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="mb-4">
          <InputEmail value={email} onChange={handleEmailChange} validate={validateEmail} />
        </div>

        <div className="mb-4">
          <InputPassword 
            value={password} 
            onChange={handlePasswordChange} 
            validate={validatePassword} />
        </div>

        <div className="mb-4">
          <label className="mb-3 block text-black dark:text-white">
            Comment
          </label>
          <textarea
            rows={6}
            placeholder="Default textarea"
            value={comment}
            onChange={handleCommentChange}
            className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          ></textarea>
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <button
            type="submit"
            className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => {
              toggleModal();
              onSave?.(false); // Вызываем функцию обратного вызова с false
            }}
            className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export default AddUser;