export const TimeFrame = [
  {
    value: "1 Minute",
    label: "1 Minute",
  },
  {
    value: "3 Minutes",
    label: "3 Minutes",
  },
  {
    value: "5 Minutes",
    label: "5 Minutes",
  },
  {
    value: "15 Minutes",
    label: "15 Minutes",
  },
  {
    value: "30 Minutes",
    label: "30 Minutes",
  },
  {
    value: "1 Hour",
    label: "1 Hour",
  },
  {
    value: "1 Week",
    label: "1 Week",
  },
]