import React from 'react'
import { Task, Column } from '../../types/test'
import { Droppable } from '@hello-pangea/dnd'
import TestTask from './TestTask'

interface TestColumnProps {
  data: Column
  tasks: Task[]
} 

const TestColumn: React.FC<TestColumnProps> = ({ tasks, data }) => {
  const {id, title, taskIds} = data

  return(
    <div className='rounded-xl border border-stroke bg-white p-5 shadow-default dark:border-strokedark dark:bg-boxdark'>
      <h2 className='mb-4 text-lg font-semibold'>{title}</h2>
      
      <Droppable droppableId={id}>
        {
          (provided, snapshot) => (
            <div 
              className=''
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {tasks.map((task, idx) => (
                <TestTask key={task.id} data={task} index={idx} />
              ))}
              {provided.placeholder}
            </div>
          )
        }
      </Droppable>
    </div>
  )
}

export default TestColumn