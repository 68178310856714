import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Strategies } from '../../../data/strateges';
import { TimeFrame } from '../../../data/timeFrame';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import InputField from '../../../components/Forms/Inputs/InputField';
import { toast } from 'sonner';
import { Bot } from '../../../types/task';
import Switcher from '../../../components/Switchers/Switcher';

interface SettingBotProps {
  toggleModal: () => void;
  setBots: (bots: (prevBots: Bot[]) => Bot[]) => void; 
  bot?: Bot; 
}

const SettingBot: React.FC<SettingBotProps> = ({ toggleModal, setBots, bot }) => {
  const [step, setStep] = useState(1);
  const [strategy, setStrategy] = useState(bot?.strategy || '');
  const [initUsdt, setInitUsdt] = useState<number | ''>(bot?.initUsdt || '');
  const [timeFrame, setTimeFrame] = useState(bot?.timeFrame || '');
  const [ruleOpen, setRuleOpen] = useState(bot?.ruleOpen || '');
  const [ruleStop, setRuleStop] = useState(bot?.ruleStop || '');
  const [openProcentMax, setOpenProcentMax] = useState<number | ''>(bot?.openProcentMax || '');
  const [stopProcent, setStopProcent] = useState<number | ''>(bot?.stopProcent || '');
  const [takeProfit, setTakeProfit] = useState<number | ''>(bot?.takeProfit || ''); 
  const [stopLose, setStopLose] = useState<number | ''>(bot?.stopLose || ''); 

  const handleStrategyChange = (value: string) => {
    setStrategy(value);
  };

  const handleTimeFrameChange = (value: string) => {
    setTimeFrame(value);
  };

  const handleNextStep = () => {
    if (!strategy) {
      toast.error('Please select a strategy');
      return;
    }
    setStep(2);
  };

  const handleBackStep = () => {
    setStep(1);
  };

  const handleInitUsdtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitUsdt(value === '' ? '' : parseInt(value, 10));
  };

  const handleRuleOpenChange = (value: string) => {
    setRuleOpen(value);
  };

  const handleRuleStopChange = (value: string) => {
    setRuleStop(value);
  };

  const handleProcentMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOpenProcentMax(value === '' ? '' : parseInt(value, 10));
  };

  const handleStopProcentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopProcent(value === '' ? '' : parseInt(value, 10));
  };

  const handleTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfit(value === '' ? '' : parseFloat(value)); 
  };

  const handleStopLoseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopLose(value === '' ? '' : parseFloat(value)); 
  };

  const handleAddBot = () => {
    const newBot: Bot = {
      strategy,
      initUsdt: Number(initUsdt),
      timeFrame,
    };

    if (strategy === 'Trend_Mashka') {
      newBot.ruleOpen = ruleOpen;
      newBot.ruleStop = ruleStop;
      newBot.openProcentMax = Number(openProcentMax);
      newBot.stopProcent = Number(stopProcent);
    } else if (strategy === 'Wave_Squeez') {
      newBot.ruleOpen = ruleOpen;
      newBot.takeProfit = Number(takeProfit); 
      newBot.stopLose = Number(stopLose); 
    }

    setBots((prevBots) => {
      if (bot) {
        const updatedBots = prevBots.map((b, index) => (b === bot ? newBot : b));
        return updatedBots;
      } else {
        return [...prevBots, newBot];
      }
    });
    toggleModal();
  };

  return (
    <>
      <div className="text-start">
        <h3 className="mb-5 text-title-md2 font-semibold text-black dark:text-white">
          {bot ? 'Edit settings for bot' : 'Add settings for bot'}
        </h3>

        {step === 1 ? (
          <div>
            <div className='mb-4'>
              <SimpleSelect
                label="Choose strategy"
                options={Strategies}
                value={strategy}
                onChange={handleStrategyChange}
                className="w-full"
              />
            </div>

            <div className="mb-4 flex gap-3 items-end justify-between">
              <InputField
                label="Init USDT"
                value={initUsdt}
                onChange={handleInitUsdtChange}
                placeholder="Min: 100$"
                type="number"
              />

              <SimpleSelect
                label="Time frame"
                options={TimeFrame}
                value={timeFrame}
                onChange={handleTimeFrameChange}
                className="w-full"
              />
            </div>

            <div className='w-full flex items-center justify-center'>
              <button
                onClick={handleNextStep}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h4 className="mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
              Settings for {strategy}
            </h4>

            {strategy === 'Trend_Mashka' && (
              <>
                <div className="mb-4 flex gap-3 items-end justify-between">
                  <SimpleSelect
                    label="Rule open"
                    options={[{ value: "EMA_4Bars", label: "EMA_4Bars" }]}
                    value={ruleOpen}
                    onChange={handleRuleOpenChange}
                    className="w-full"
                  />

                  <SimpleSelect
                    label="Rule stop"
                    options={[{ value: "EMA_Procent", label: "EMA_Procent" }]}
                    value={ruleStop}
                    onChange={handleRuleStopChange}
                    className="w-full"
                  />
                </div>

                <div className="mb-4 flex gap-3 items-center justify-between">
                  <InputField
                    label="Open procent max"
                    value={openProcentMax}
                    onChange={handleProcentMaxChange}
                    placeholder="50%"
                    type="number"
                  />

                  <InputField
                    label="Stop procent"
                    value={stopProcent}
                    onChange={handleStopProcentChange}
                    placeholder="85%"
                    type="number"
                  />
                </div>
              </>
            )}

            {strategy === 'Wave_Squeez' && (
              <>
                <div className="mb-4 flex gap-3 items-end justify-between">
                  <SimpleSelect
                    label="Rule open"
                    options={[{ value: "ChangeWay", label: "ChangeWay" }]}
                    value={ruleOpen}
                    onChange={handleRuleOpenChange}
                    className="w-full"
                  />
                </div>

                <div className="mb-4 flex gap-3 items-center justify-between">
                  <InputField
                    label="Take profit"
                    value={takeProfit}
                    onChange={handleTakeProfitChange}
                    placeholder="%"
                    type="number"
                  />

                  <InputField
                    label="Stop lose"
                    value={stopLose}
                    onChange={handleStopLoseChange}
                    placeholder="%"
                    type="number"
                  />
                </div>
              </>
            )}

            <div className='w-full flex items-center justify-center gap-4'>
              <button
                onClick={handleBackStep}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md border border-primary py-4 px-10 text-center font-medium text-primary hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                Back
              </button>
              <button
                onClick={handleAddBot}
                type="button"
                className='w-full inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10'
              >
                {bot ? 'Save changes' : 'Add bot'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingBot;