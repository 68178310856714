export const Strategies = [
  {
    value: "Trend_Mashka",
    label: "Trend_Mashka",
  },
  {
    value: "Trend_Squeez",
    label: "Trend_Squeez",
  },
  {
    value: "Wave_Squeez",
    label: "Wave_Squeez",
  },
  {
    value: "ConterTrend_Squeez",
    label: "ConterTrend_Squeez",
  },
  {
    value: "Ludik",
    label: "Ludik",
  },
  {
    value: "Constructor",
    label: "Constructor",
  },
]