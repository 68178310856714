import React, {useState} from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableActions from '../../components/Tables/TableActions';

const Dashboard: React.FC = () => {
  return (
    <>
      <Breadcrumb pageName="Api keys" />
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12">
          <TableActions />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
