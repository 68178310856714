import React, { useState, useEffect, useContext } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import AddTask from './AddTask';
import axios from 'axios';
import Loader from '../../common/Loader/index';
import { TASK } from '../../types/task';
import { Toaster, toast } from 'sonner';
import TableTasks from '../../components/Tables/TableTasks';
import TaskFilters from './TaskFilters';
import { UserContext } from '../../context/UserContext'; 

const Benchmark: React.FC = () => {
  const [tasks, setTasks] = useState<TASK[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalAddTask, setIsModalAddTask] = useState(false);

  const { user } = useContext(UserContext); 

  const toggleModalAddTask = () => {
    setIsModalAddTask(!isModalAddTask);
  };

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = () => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('/get-tasks');
        setTasks(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTasks();
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('Task added successfully');
      getTasks(); 
    } else {
      toast.error('Failed to add task');
    }
  };

  const handleTasksFiltered = (filteredTasks: TASK[]) => {
    setTasks(filteredTasks);
  };

  const addToFavorites = async (taskId: string) => {
    if (!user) return;
    try {
      await axios.post('/add-favorite', { taskId });
      setTasks((prevTasks) => {
        if (!prevTasks) return prevTasks;
        return prevTasks.map(task => task._id === taskId ? { ...task, isFavorite: true } : task);
      });
      toast.success('Task added to favorites');
    } catch (error) {
      toast.error('Failed to add task to favorites');
    }
  };

  const removeFromFavorites = async (taskId: string) => {
    if (!user) return;
    try {
      await axios.delete('/remove-favorite', { data: { taskId } });
      setTasks((prevTasks) => {
        if (!prevTasks) return prevTasks;
        return prevTasks.map(task => task._id === taskId ? { ...task, isFavorite: false } : task);
      });
      toast.success('Task removed from favorites');
    } catch (error) {
      toast.error('Failed to remove task from favorites');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb pageName={`Benchmark ${tasks?.length > 0 ? `(${tasks.length})` : ""}`} />

      <div className='mb-5 rounded-lg border border-stroke bg-white py-3 px-4.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
        <TaskFilters onTasksFiltered={handleTasksFiltered} />
        <button
          onClick={toggleModalAddTask}
          className='flex items-center gap-2 rounded bg-primary py-2 px-4.5 font-medium text-white hover:bg-opacity-90 modal-show-button'
        >
          Add task
        </button>
        <ModalSideRight
          isOpen={isModalAddTask}
          toggleModal={toggleModalAddTask}
          title="ADD TASK"
          content={<AddTask toggleModal={toggleModalAddTask} updateTasks={getTasks} onSave={handleSave} />}
        />
      </div>

      <TableTasks 
        tasks={tasks} 
        updateTasks={getTasks} 
        addToFavorites={addToFavorites} 
        removeFromFavorites={removeFromFavorites} 
      />
    </>
  );
};

export default Benchmark;