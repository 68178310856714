import React from 'react'
import { Task } from '../../types/test'
import { Draggable } from '@hello-pangea/dnd'

interface TestTaskProps {
  data: Task,
  index: number
}

const TestTask: React.FC<TestTaskProps> = ({data, index}) => {
  const {id, content} = data

  return(
    <Draggable draggableId={id} index={index}>
      {
        (provided, snapshot) => (
          <div 
            className={`mb-3 cursor-move rounded-xl py-3 px-5 dark:bg-boxdark-2 
              ${snapshot.isDragging ? 'opacity-75' : ''}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef} >
            {content}
          </div>
        )
      }
    </Draggable>
  )
}

export default TestTask