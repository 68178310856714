import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

const Settings: React.FC = () => {
  return (
    <>
      <Breadcrumb pageName="Настройки" />

      
    </>
  );
};

export default Settings;
