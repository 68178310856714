import React, { useState, useEffect, useContext } from 'react';
import SimpleSelect from "../../components/Forms/Select/SimpleSelect";
import Switcher from "../../components/Switchers/Switcher";
import axios from 'axios';
import { UserContext } from '../../context/UserContext'; 

interface TaskFiltersProps {
  onTasksFiltered: (tasks: any[]) => void;
}

const TaskFilters: React.FC<TaskFiltersProps> = ({ onTasksFiltered }) => {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [showFavorite, setShowFavorite] = useState(false);
  const [symbols, setSymbols] = useState<{ value: string; label: string }[]>([]);
  const { user } = useContext(UserContext); 

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await axios.get('/get-benchmark-symbols');
        const symbolsData = response.data.map((symbol: { name: string }) => ({
          value: symbol.name,
          label: symbol.name,
        }));
        setSymbols(symbolsData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSymbols();
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    handleFilter(undefined, undefined, e.target.value);
  };

  const handleSymbolChange = (value: string) => {
    setSymbol(value);
    handleFilter(undefined, value);
  };

  const handleShowFavoriteChange = () => {
    setShowFavorite(prevShowFavorite => {
      const newShowFavorite = !prevShowFavorite;
      handleFilter(newShowFavorite);
      return newShowFavorite;
    });
  };

  const handleFilter = async (newShowFavorite?: boolean, newSymbol?: string, newName?: string) => {
    const showFavoriteValue = newShowFavorite !== undefined ? newShowFavorite : showFavorite;
    const symbolValue = newSymbol !== undefined ? newSymbol : symbol;
    const nameValue = newName !== undefined ? newName : name;

    try {
      const response = await axios.get('/filter-tasks', {
        params: {
          name: nameValue,
          symbol: symbolValue,
          userId: user ? user.id : '', 
          showFavorite: showFavoriteValue ? 'true' : 'false', 
        },
      });
      onTasksFiltered(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilters = () => {
    setName('');
    setSymbol('');
    setShowFavorite(false);
    handleFilter(false, '', '');
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <h3 className='font-semibold text-black dark:text-white mb-4'>Filters</h3>

        <button onClick={handleClearFilters} className="text-blue-500 hover:text-blue-700">
          Clean filters
        </button>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
        <div className="relative w-full md:w-1/2">
          <button className="absolute left-2 top-1/2 -translate-y-1/2">
            <svg
              className="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                fill=""
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                fill=""
              />
            </svg>
          </button>

          <input
            type="text"
            placeholder="Type to search..."
            value={name}
            onChange={handleNameChange}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 pl-8 pr-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="flex flex-col sm:flex-row w-full md:w-1/2 justify-between items-start sm:items-center gap-4">
          <SimpleSelect
            options={symbols}
            value={symbol}
            onChange={handleSymbolChange}
            className="w-full"
          />

          <div className='flex items-center gap-2'>
            <p className='text-black dark:text-white'>Favorites</p>
            <Switcher 
              isActive={showFavorite} 
              onChange={handleShowFavoriteChange} 
              id="show-favorite-tasks" 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskFilters;