import React, { useState, useContext } from 'react';
import { BsFillPencilFill, BsFillTrashFill, BsExclamationCircle, BsStar, BsStarFill } from "react-icons/bs";
import ModalSideRight from '../Modals/ModalSideRight';
import ModalCenter from '../Modals/ModalCenter';
import EditTask from '../../pages/Benchmark/EditTask';
import DeleteTask from '../../pages/Benchmark/DeleteTask';
import { TASK } from '../../types/task';
import { Toaster, toast } from 'sonner';
import { UserContext } from '../../context/UserContext'; 
import axios from 'axios';
import Pagination from '../../components/Pagination/Pagination'; 

interface TasksProps {
  tasks: TASK[];
  updateTasks: () => void;
  addToFavorites: (taskId: string) => void;
  removeFromFavorites: (taskId: string) => void;
}

const TableTasks: React.FC<TasksProps> = ({ tasks, updateTasks, addToFavorites, removeFromFavorites }) => {
  const { user } = useContext(UserContext); 
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TASK | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const tasksPerPage = 10; 
  const tasksArray = tasks || [];
  const pageCount = Math.ceil(tasksArray.length / tasksPerPage);
  const offset = currentPage * tasksPerPage;
  const currentTasks = tasksArray.slice(offset, offset + tasksPerPage);

  const toggleModalDelete = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const toggleModalEdit = () => {
    setIsModalEditOpen(!isModalEditOpen);
  };

  const handleEditTask = (task: TASK) => {
    setSelectedTask(task);
    toggleModalEdit();
  };

  const handleDeleteTask = (task: TASK) => {
    setSelectedTask(task);
    toggleModalDelete();
  };

  const handleEditSave = (result: boolean) => {
    if (result) {
      toast.success('Task edit successfully');
    } else {
      toast.error('Failed to edit task');
    }
  };

  const handleDeleteConfirm = (result: boolean) => {
    if (result) {
      toast.success('Task delete successfully');
    } else {
      toast.error('Failed to delete task');
    }
  };

  const handleFavorite = async (taskId: string) => {
    if (!user) return;
    if (tasksArray.find(task => task._id === taskId)?.isFavorite) {
      removeFromFavorites(taskId);
    } else {
      addToFavorites(taskId);
    }
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };


  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className='overflow-x-auto rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1'>
        <div className="flex flex-col">
          <div className="grid grid-cols-6 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-6">
            <div className="p-2.5 xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                #
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                NAME
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                STATUS
              </h5>
            </div>
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                RUN/COMPLETE
              </h5>
            </div>
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                ACTIONS
              </h5>
            </div>
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                FAVORITE
              </h5>
            </div>
          </div>

          {currentTasks.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-5">
              <BsExclamationCircle className="text-4xl text-gray-400 mb-2" />
              <p className="text-gray-600">No tasks found</p>
            </div>
          ) : (
            currentTasks.map((task, key) => (
              <div
                className={`grid grid-cols-6 sm:grid-cols-6 ${
                  key === currentTasks.length - 1
                    ? ''
                    : 'border-b border-stroke dark:border-strokedark'
                }`}
                key={key}
              >
                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="text-black dark:text-white">{offset + key + 1}</p>
                </div>

                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <p
                    className="hidden text-black dark:text-white sm:block overflow-hidden whitespace-nowrap text-ellipsis"
                    title={task.name}
                  >
                    {task.name}
                  </p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="text-black dark:text-white">{task.status}</p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="text-meta-3">{`${task.dateStartWork}/${task.dateEndWork}`}</p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <span className="actions flex grid-cols-2 gap-4">
                    <BsFillPencilFill
                      className="edit-btn cursor-pointer"
                      onClick={() => handleEditTask(task)} />

                    <ModalSideRight
                      isOpen={isModalEditOpen}
                      toggleModal={toggleModalEdit}
                      title="EDIT TASK"
                      content={
                        selectedTask && (
                          <EditTask 
                            toggleModal={toggleModalEdit} 
                            task={selectedTask} 
                            updateTasks={updateTasks}
                            onSave={handleEditSave}
                          />
                        )
                      } />

                    <BsFillTrashFill
                      className="delete-btn cursor-pointer"
                      onClick={() => handleDeleteTask(task)}
                    />

                    <ModalCenter
                      isOpen={isModalDeleteOpen}
                      toggleModal={toggleModalDelete}
                      content={
                        selectedTask && (
                          <DeleteTask 
                            toggleModal={toggleModalDelete} 
                            idTask={selectedTask._id}
                            updateTasks={updateTasks}
                            onDelete={handleDeleteConfirm} 
                          />
                        )
                      }
                    />
                  </span>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <button
                    onClick={() => handleFavorite(task._id)}
                    className="text-xl"
                  >
                    {task.isFavorite ? <BsStarFill className="text-yellow-500" /> : <BsStar className="text-gray-400" />}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {currentTasks.length != 0 && 
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
          />
        }
        
      </div>
    </>
  );
};

export default TableTasks;