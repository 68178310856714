import { BsFillTrashFill, BsFillPencilFill, BsFillSendFill } from "react-icons/bs";
import { BRAND } from '../../types/brand';
import React, { useState } from 'react';
import ModalCenter from '../Modals/ModalCenter';
import ModalSideRight from "../Modals/ModalSideRight";
import ContentModalAddKey from "../../pages/ApiKeys/ContentModalAddKey";
import ContentModalEdit from "../../pages/ApiKeys/ContentModalEdit";
import ContentModalConfirm from '../../pages/ApiKeys/ContentModalConfirm'
import ContentModalSend from '../../pages/ApiKeys/ContentModalSend'


const brandData: BRAND[] = [
  {
    name: "dfjns'ldnf'sldknf'lsnkjknfkjsbvjdffvdjfvn",
    visitors: 3.5,
    revenues: '5,768',
    sales: 590,
    conversion: 4.8,
  },
  {
    name: "b;kdbfs;djf'sldfjns'ldn'vndfmdf",
    visitors: 2.2,
    revenues: '4,635',
    sales: 467,
    conversion: 4.3,
  },
  {
    name: "eytuyoipiyhib43436ljvl-997gfc",
    visitors: 2.1,
    revenues: '4,290',
    sales: 420,
    conversion: 3.7,
  },
  {
    name: "khbrobjlbn'lxznxb;zddnbe5645yjrrgbfgg",
    visitors: 1.5,
    revenues: '3,580',
    sales: 389,
    conversion: 2.5,
  },
  {
    name: "hvclhgvlgvlgvlui.,.3453bndbklvnz",
    visitors: 3.5,
    revenues: '6,768',
    sales: 390,
    conversion: 4.2,
  },
];

const TableActions = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const onConfirm = () => {
    toggleModal();
    setTimeout(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }, 300); // Задержка в 300 мс, чтобы уведомление появилось после закрытия модального окна
  };

  const [isModalSideOpen, setIsModalSideOpen] = useState(false);

  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  const [isModalEditOpen, sestIsModalEditOpen] = useState(false)

  const toggleModalEdit = () => {
    sestIsModalEditOpen(!isModalEditOpen)
  }

  const [isModalSend, setIsModalSend] = useState(false)

  const toggleModalSend = () => {
    setIsModalSend(!isModalSend)
  }

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      
      <div className="mb-4 flex justify-between items-center">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          Keys
        </h4>
        <button 
          onClick={toggleModalSide}
          className="flex items-center gap-2 rounded bg-primary py-2 px-4.5 font-medium text-white hover:bg-opacity-90 modal-show-button">
          <svg 
            className="fill-current" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
              <path d="M15 7H9V1C9 0.4 8.6 0 8 0C7.4 0 7 0.4 7 1V7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H7V15C7 15.6 7.4 16 8 16C8.6 16 9 15.6 9 15V9H15C15.6 9 16 8.6 16 8C16 7.4 15.6 7 15 7Z" fill=""></path>
              </svg>
            Add key
        </button>
        
        <ModalSideRight
          isOpen={isModalSideOpen}
          toggleModal={toggleModalSide}
          title="NEW KEY"
          content={ContentModalAddKey}
        />
      </div>

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-6">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              #
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              NAME
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              ACCOUNT TYPE
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              BALANCE
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              BOT NAME
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              ACTIONS
            </h5>
          </div>
        </div>

        {brandData.map((brand, key) => (
          <div
            className={`grid grid-cols-3 sm:grid-cols-6 ${
              key === brandData.length - 1
                ? ''
                : 'border-b border-stroke dark:border-strokedark'
            }`}
            key={key}
          >
            <div className="flex items-center gap-3 p-2.5 xl:p-5">
              <p
                className="hidden text-black dark:text-white sm:block overflow-hidden whitespace-nowrap text-ellipsis"
                title={brand.name}
              >
                {brand.name}
              </p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-black dark:text-white">{brand.visitors}K</p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-meta-3">${brand.revenues}</p>
            </div>

            <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
              <p className="text-black dark:text-white">{brand.sales}</p>
            </div>

            <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
              <p className="text-meta-5">{brand.conversion}%</p>
            </div>

            <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
              <span className="actions flex grid-cols-2 gap-4">
                <BsFillTrashFill
                  className="delete-btn cursor-pointer"
                  onClick={toggleModal}
                 />

                <ModalCenter
                  isOpen={isModalOpen}
                  toggleModal={toggleModal}
                  content={<ContentModalConfirm toggleModal={toggleModal} />}
                />

                <BsFillPencilFill
                  className="edit-btn cursor-pointer"
                  onClick={toggleModalEdit} />

                <ModalSideRight
                  isOpen={isModalEditOpen}
                  toggleModal={toggleModalEdit}
                  title="EDIT"
                  content={ContentModalEdit}
                />

                <BsFillSendFill 
                  className="send-btn cursor-pointer"
                  onClick={toggleModalSend} />

                <ModalCenter
                  isOpen={isModalSend}
                  toggleModal={toggleModalSend}
                  content={<ContentModalSend toggleModal={toggleModalSend} />}
                />
                
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableActions;
