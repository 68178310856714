import React, { useState, useEffect } from 'react';
import { DownloadItem } from '../../../types/period';

interface MultipleChooseProps {
  periods: DownloadItem[];
  selectedPeriods: DownloadItem[];
  onSelect: (period: DownloadItem) => void;
  onDeselect: (period: DownloadItem) => void;
}

const MultipleChoose: React.FC<MultipleChooseProps> = ({ periods, selectedPeriods, onSelect, onDeselect }) => {
  // Сортируем периоды по дате (от новых к старым)
  const sortedPeriods = periods.sort((a, b) => new Date(b.data.day).getTime() - new Date(a.data.day).getTime());

  const [selected, setSelected] = useState<DownloadItem[]>(selectedPeriods);

  useEffect(() => {
    setSelected(selectedPeriods);
  }, [selectedPeriods]);

  const isSelected = (period: DownloadItem) => {
    return selected.some(p => p.periodId === period.periodId);
  };

  const handleSelect = (period: DownloadItem) => {
    if (isSelected(period)) {
      onDeselect(period);
    } else {
      onSelect(period);
    }
  };

  return (
    <div className='mb-4'>
      <h2>All periods for {sortedPeriods[0]?.data?.symbol || 'Unknown Symbol'}</h2>
      <div className="max-h-60 overflow-y-auto shadow-scroll">
        <ul>
          {sortedPeriods.map((period, index) => (
            <li key={index} onClick={() => handleSelect(period)}>
              <div 
                className={`cursor-pointer my-1.5 flex items-center justify-start rounded border-[1.5px] px-2.5 py-1.5 text-sm font-medium dark:bg-form-input dark:text-white dark:focus:border-primary ${isSelected(period) ? 'border-primary bg-blue-200' : 'border-stroke bg-transparent dark:border-strokedark'}`}
              >
                <div className="max-w-full flex-initial text-black dark:text-white">{period.data.filename}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {selected.length > 0 && (
        <div>
          <h3 className='my-2'>{`Selected Periods ${selected.length}`}</h3>
          <div className="max-h-60 overflow-y-auto shadow-scroll">
            <ul>
              {selected.map((period, index) => (
                <li key={index} onClick={() => onDeselect(period)} className='cursor-pointer'>
                  <div className="my-1.5 flex items-center justify-center rounded border-[1.5px] border-primary bg-transparent px-2.5 py-1.5 text-sm font-medium dark:border-primary dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <div className="max-w-full flex-initial text-black dark:text-white">{period.data.filename}</div>
                    <div className="flex flex-auto flex-row-reverse">
                      <div className="cursor-pointer pl-2 hover:text-danger text-white">
                        <svg className="fill-current" role="button" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z" fill="currentColor"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleChoose;